import './App.css'
import Lander from './views/landing'

function App() {

  return (
    <div className="App">
      <Lander/>
    </div>
  );
}

export default App;

//box-shadow: 0px 0px 1000px 1000px rgba(0, 0, 0, 0.11)
